.dashboard-container {
	height: 100vh;
	//overflow: auto;
}

.dashboard-header {
	position: sticky;
	top:0;
	display: flex;
	align-items: center;
	height: 8rem;
	border-bottom: .1px solid $outline-color;
	margin-left: 7rem;
	background-color: white;
	transition: margin-left .3s;
	z-index: 1250; // z-index of sidebar is 1200 so for the open sidebar button to be on top of the content and sidebar value must be above 1200 but below 1300 because of profile menu z-index
}

.dashboard-header-shifted {
	position: sticky;
	top:0;
	margin-left: 24rem;
	display: flex;
	align-items: center;
	height: 8rem;
	border-bottom: .1px solid $outline-color;
	background-color: white;
	z-index: 1250; // z-index of sidebar is 1200 so for the open sidebar button to be on top of the content and sidebar value must be above 1200 but below 1300 because of profile menu z-index
	transition: margin-left .3s;;
}

.dashboard-logo-img {
	display:flex;
	flex-direction: column;
	margin-left: 1.4rem;
	height: 6rem;
}

.dashboard-content {
	display: flex;
	flex-direction: column;
	height: calc(100% - 7rem);
	//height: 100%;
	margin-left: 7rem;
	transition: margin-left .3s;
}

.dashboard-content-shifted {
	display: flex;
	flex-direction: column;
	height: calc(100% - 7rem);
	//height: 100%;
	margin-left: 24rem;
	transition: margin-left .3s;
}

.dashboard-sidebar-open {
	display: flex;
	width: 24rem;
	background-color: $sidebar;
	overflow-y: auto;
	overflow-x: hidden;
	transition: width .3s;
	padding-bottom: 5rem;
	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $text-btn-color;
		border-radius: 5rem;


		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $input-background;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem $input-background;
	}
}

.dashboard-sidebar-close {
	display: flex;
	width: 7rem;
	background-color: $sidebar;
	overflow-y: auto;
	overflow-x: hidden;
	transition: width .3s;

	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;


		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $input-background;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem $input-background;
	}
}

.dashboard-sidebar-logo {
	display: flex;
	align-items: center;
	padding-top: 1.8rem;
	padding-bottom: 1.8rem;
	width: 24rem;
	padding-left: 1.3rem;
	border-bottom: .1rem solid $sidebar-border;
}

.dashboard-sidebar-name {
	font-family: $font-bold;
	font-size: 1.4rem;
	color: white;
}

.dashboard-product-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.4rem;
	height: 4.4rem;
	border-radius: 50%;
	background-color: $sidebar-logo-background;
	margin-right: 1.2rem;
}

.dashboard-sidebar-img {
	width: 4.4rem;
	height: 4.4rem;
}

.dashboard-main-menu {
	padding-top: 5.4rem;
	padding-bottom: 4rem;
	border-bottom: .1rem solid $sidebar-border;
}

.sidebar-list-item {
	padding: 0 1.3rem;
	color: $sidebar-icon;
	height: 4.6rem;
		&:hover {
			background-color: rgba(255, 255, 255, 0.1);

			.sub-item-icon {
				color: white;
			}
		}
		&.Mui-selected {
			color: white;
			background-color: $blue-theme-base;

			.sidebar-icon-wrapper {
				display: flex;
				color: white;
				min-width:2.4rem;
				padding-top: .1rem;
				padding-bottom: .1rem;
			}
		}

		&.Mui-selected:hover {
			background-color: $blue-theme-base;
		}

		&--hidden {
			@extend .sidebar-list-item;

			&.Mui-selected, &:hover {
				color: white;
				background-color: transparent;

				.sidebar-icon-wrapper {
					display: flex;
					color: white;
					min-width:2.4rem;
					padding-top: .1rem;
					padding-bottom: .1rem;
					background-color: $blue-theme-base;
					border-radius: 50%;
				}
			}

			&.Mui-selected:hover {
				background-color: transparent;
			}
		}

		&--disabled {
			@extend .sidebar-list-item;
			//visibility: hidden;
			display: none;
		}

}

.sidebar-sub-item {
	@extend .sidebar-list-item;
	background-color: $sidebar-submenu;

	&.Mui-selected {
		color: $blue-theme-base;
		background-color: $sidebar-submenu;
	}

	&.Mui-selected:hover {
		background-color: $sidebar-submenu;
	}
}

.sidebar-subitem-submenu {
	padding-left: 2.5rem;
}

.sidebar-icon {
	display: flex;
	//color: $sidebar-icon;
	min-width:2.4rem;
	padding-top: .1rem;
	padding-bottom: .1rem;
}

.sidebar-item-text, .sidebar-item-text--sub-item {
	//color: $sidebar-icon;
	margin-left: 1.2rem;
	visibility: visible;
	transition: visibility 0s .15s;

	&--hidden {
		visibility: hidden;
	}

}

.sidebar-item-text .MuiTypography-body1 {
	font-family: $font-regular;
	font-size: 1.4rem;
}

.sidebar-item-text--sub-item .MuiTypography-body1 {
	font-family: $font-regular;
	font-size: 1.2rem;
}

.sidebar-icon-wrapper {
	width: 4.4rem;
	height: 4.4rem;
	background-color: transparent;
	color: $sidebar-icon;
}

.dashboard-submenu {
	padding-top: 2.8rem;
}

.dashboard-sidebar-controls {
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	background-color: white;
	border: .1rem solid $outline-color;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: .1rem .1rem .2rem rgba(21, 27, 38, 0.05);
	left: 5.5rem;
	// z-index of sidebar is 1200 so to be on top of the content and sidebar value must be above 1200
	margin-left: -1.5rem;
	margin-top: 9.2rem;
	cursor: pointer;
	align-self: flex-start;
}

.profile-menu-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .6rem;
	width: 4.2rem;
	height: 4.2rem;
	border-radius: 50%;
	border: .2rem solid $outline-color;
	background-color: white;
	margin-left: auto;
	margin-right: 3.2rem;
	cursor: pointer;

	&:hover {
		border: .2rem solid $blue-theme-base;
	}

	&--open {
		@extend .profile-menu-btn;
		border: .2rem solid $blue-theme-base;
	}
}

.profile-info {
	padding-left: 2.5rem;
	padding-top: 2.5rem;
	padding-bottom: 2rem;
}

.profile-name {
	font-family: $font-bold;
	font-size: 1.6rem;
	line-height: 1.9rem;
}

.profile-position {
	font-size: 1.4rem;
	line-height: 1.9rem;
}

.profile-img {
	width: 3.6rem;
	height: 3.6rem;
	background-color: $blue-theme-light;
	color: $blue-theme-base;
}

.profile-menu {
	width: 32rem;
	padding-bottom: 2rem;
	box-shadow: 0 2.5rem 6rem $outline-color;
	border: .1rem solid $outline-color;
	color: $text-main-color;
	margin-top: .8rem;

	.MuiList-padding {
		padding: 0;
	}
}

.profile-menu-item {
	font-size: 1.4rem;
	//padding-left: 2.5rem;
	//padding-right: 2.5rem;
	padding: 1.5rem 2.5rem;
	font-family: $font-regular;

	.profile-menu-icon .MuiSvgIcon-root {
		width: 2rem;
		height: 2rem;
		color: $text-main-color;
	}

	&:hover {
		background-color: transparent;
		color: $blue-theme-base;

		.profile-menu-icon .MuiSvgIcon-root {
			width: 2rem;
			height: 2rem;
			color: $blue-theme-base;
		}

	}

}

.profile-menu-icon {
	min-width: 2rem;
}

.profile-menu-text {
	padding-left: 2.1rem;
}

.profile-menu-text .MuiTypography-body1 {
	font-size: 1.4rem;
	font-family: $font-regular;
}

.Toastify__toast-body {
	font-family: $font-medium;
	font-size: 1.4rem;
}

.btn-icon {
	width: 2.4rem;
	height: 2.4rem;
	color: $text-main-color;
}

.sub-item-icon {
	color: transparent;
}

.skeleton-profile-menu-btn {
	width: 4.2rem;
	height: 4.2rem;
	margin-left: auto;
	margin-right: 3.2rem;
}

.skeleton-list-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1.3rem;
	height: 4.6rem;

	.MuiSkeleton-wave::after {
		background: linear-gradient(90deg, transparent,rgba(0,0,0,0.2),transparent);
	}
}

.skeleton-sidebar-icon-wrapper {
	display: flex;
	width: 4.4rem;
	height: 4.4rem;
	min-width: 4.4rem;
	background-color: $sidebar-icon;
	padding-top: .1rem;
	padding-bottom: .1rem;
}

.skeleton-sidebar-item-txt {
	display: flex;
	height: 3rem;
	width: 14rem;
	min-width: 10rem;
	margin-left: 1.8rem;
	background-color: $sidebar-icon;
}