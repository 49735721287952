.homepage-container {
	display: flex;
	flex-direction: column;
	//height: calc(100vh - 12rem);
	flex-grow: 1;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
	background-color: $input-background;

}

.homepage-title {
	font-family: $font-bold;
	font-size: 3.4rem;
	margin-bottom: .6rem;
}

.homepage-subtitle {
	font-size: 1.2rem;
	margin-bottom: 3.2rem;
}

.homepage-content {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: .6rem;
	border: .1rem solid $outline-color;
}

.dashboard-content-icon {
	height: 17rem;
	width: 17rem;
	background-color: $blue-theme-light;
	color: $blue-theme-base;
	font-size: 180rem;
}

.dashboard-content-text {
	display: block;
	margin-left: 3.6rem;
	width: 45rem;
	font-size: 1.6rem;
	line-height: 155%;
}

.content-title {
	font-size: 4.8rem;
	color: $blue-theme-base;
	line-height: 5.8rem;
	margin-bottom: 2rem;
}

.error-content {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: .6rem;
	border: .1rem solid $outline-color;
	&--table {
		@extend .error-content;
		border: none;
		padding-top: 13rem;
		padding-bottom: 13rem;
		align-items: flex-start;
	}
}

.error-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
	background-color: $input-background;
}

.error-message__container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.btn__reload {
	margin-top: 4rem;
	background-color: white;
	border: .1rem solid $blue-theme-base;
	border-radius: .5rem;
	padding: 1.6rem 3.2rem 1.6rem 3.2rem;
	color: $blue-theme-base;
	font-family: $font-medium;
	cursor: pointer;
	text-transform: none;
	font-size: 1.4rem;

	&:hover {
		background-color: scale-color($blue-theme-light, $lightness: 10%);
	}
}

.btn__back {
	margin-top: 4rem;
	background-color: $blue-theme-base;
	color: white;
	border: none;
	border-radius: .5rem;
	padding: 1.6rem 3.2rem 1.6rem 3.2rem;
	font-family: $font-medium;
	cursor: pointer;
	margin-left: 3rem;
	text-transform: none;
	font-size: 1.4rem;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}	