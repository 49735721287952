.footer-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 5rem;
	flex-shrink: 0;
	background-color: $input-background;
}

.footer-text {
	line-height: 1.2rem;
	margin-right: 3rem;
}