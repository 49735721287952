.page-container {
	display: flex;
	flex-direction: column;
	//height: calc(100vh - 12rem);
	flex-grow: 1;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
	background-color: $input-background;
}

.form {
	margin-top: 3rem;
}

.title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 3.2rem;
}

.back-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.2rem;
	height: 4.2rem;
	border-radius: 50%;
	border: .1rem solid $outline-color;
	background-color: white;
	margin-right: 1.6rem;
	text-transform: none;
	min-width: 1rem;
	cursor: pointer;

	&:hover {
		background-color: white;
	}

	.MuiSvgIcon-root {
		width: 2.4rem;
		height: 2.4rem;
		color: $text-btn-color;
	}
}

.page-content {
	display: flex;
	flex-direction: column;
	//flex-grow: 1; verificar se não vai afetar o design da pagina de createEdit. Retirado por causa da pagina de detalhe da organização
	width: 100%;
	align-items: center;
	background-color: white;
	border-radius: .6rem;
	border: .1rem solid $outline-color;
}

.form-controls {
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: .1rem solid $outline-color;
	height: 8.1rem;

	.MuiStepper-root {
		padding: 0;
	}

	.MuiPaper-root {
		background-color: transparent;
	}

	.MuiTypography-body2 {
		font-family: $font-semibold;
		font-size: 1.4rem;
		line-height: normal;
	}

	.MuiStep-horizontal {
		min-width: 18.4rem;
		padding: 0;
	}

	.MuiStepLabel-iconContainer {
		padding-right: .2rem;
	}
}

.button-style {
	width: 100%;
	height: 8.1rem;
	margin: 0;
	padding: 0;
	box-sizing: content-box;
	font-family: $font-medium;
	font-size: 1.4rem;
	color: $sidebar-icon;
	.MuiStepLabel-label {
		color: $sidebar-icon;
	}

	&--active {
		width: 100%;
		height: 8.1rem;
		margin: 0;
		padding: 0;
		box-sizing: content-box;
		border-bottom: .4rem solid $blue-theme-base;
		color: $blue-theme-base;
		font-family: $font-medium;
		font-size: 1.4rem;

		.MuiStepLabel-label.MuiStepLabel-active {
			color: $blue-theme-base;
		}

	}
}

.active-button-style {
	border-bottom: .4rem solid $blue-theme-base;
}

.page-form-container {
	padding: 5rem 0 10rem 0;
	width: 60rem;
}

.create-edit-form {
	padding-top: 2rem;
}

.create-edit-form-input {
	height: 5rem;
	width: 100%;
	border: none;
	border-radius: .6rem;
	background-color: $input-background;
	margin-top: .8rem;
	padding: 1rem 1rem 1rem 1.6rem;
	font-family: $font-regular;
	font-size: 1.4rem;
	color: $text-main-color;
}

.input__text-area {
	width: 100%;
	height: 16rem;
	border: none;
	border-radius: .6rem;
	background-color: $input-background;
	margin-bottom: .8rem;
	padding: 1rem 1rem 1rem 1.6rem;
	font-family: $font-regular;
	font-size: 1.4rem;
	color: $text-main-color;

	&:focus {
		@extend .input__text-area;
		outline: none;
		border: .1rem solid $blue-theme-base;
	}

	&--error {
		@extend .input__text-area;
		border: .1rem solid $color-error-base;
		&:focus {
			@extend .input__text-area--error;
		}
	}
}

.create-edit-label {
	font-family: $font-medium;
	font-size: 1.2rem;
}

.input-container {
	margin-top: 3rem;

	.MuiInput-root {
		width: 100%;
		margin-top: .8rem;
		border-radius: .5rem;
		border: none;
		padding: 0;


		&:before {
			border:none;
			
		}

		&:after {
			border: none;
			
		}
	}

	.MuiInput-input {
		padding: 0;
	}

	.MuiSelect-select {
		border-radius: .5rem;
		padding-left: 1.6rem;

		&:focus {
			background-color: $input-background;
		}
	}

	.MuiSelect-icon {
		top: calc(50% - 1rem);
		right: 1.5rem;
		width: 2.4rem;
		height: 2.4rem;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border: none;
	}

}

.select-input {
	display: flex;
	align-items: center;
	width: 100%;
	height: 5rem;
	background-color: $input-background;
	border: none;
	font-family: $font-regular;
	font-size: 1.4rem;
	color: $text-btn-color;
	margin-bottom: 0.8rem;

	&:focus {
		border: .1rem solid $blue-theme-base;
	}

	&:disabled {
		opacity: 0.5;
	}

	&--error {
		@extend .select-input;
		border: .1rem solid $color-error-base;
		&:focus, :active {
			@extend .select-input--error;
		}
	}
}

.step-control-container {
	display: flex;
	align-items: center;
	padding-top: 5rem;
	width: 100%;
}

.container__edit-controls {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	border-top: .1rem solid $outline-color;
	padding: 3rem;
}

.btn-color-filled {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18rem;
	height: 5rem;
	//padding: 1.6rem 5rem 1.6rem 5rem;
	background-color: $blue-theme-base;
	border-radius: .6rem;
	border: none;
	color: white;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}

	&__modal {
		@extend .btn-color-filled;
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
		width: 100%;
	}

	&:disabled {
		color: white;
		opacity: 0.5;
	}
}

.btn-color-inverted {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.6rem 5rem 1.6rem 5rem;
	width: 18rem;
	height: 5rem;
	background-color: white;
	border-radius: .6rem;
	border: .2rem solid $blue-theme-base;
	color: $blue-theme-base;
	font-family: $font-medium;
	font-size: 1.4rem;
	margin-right: 1rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-light, $lightness: 10%);
	}

	&__modal {
		@extend .btn-color-inverted;
		margin-top: 1.6rem;
		margin-bottom: 1.6rem;
		width: 100%;
	}

	&:disabled {
		opacity: 0.5;
		color: $blue-theme-base;
	}
}

.step-control-icon-right {
	height: 2.4rem;
	width: 2.4rem;
	margin-left: 1rem;
}

.step-control-icon-left {
	height: 2.4rem;
	width: 2.4rem;
	margin-right: 1rem;
}

.group-input-container {
	display: flex;
	flex-direction: column;
	padding-top: 3.3rem;
}

.group-input-title {
	font-family: $font-bold;
	font-size: 1.8rem;
}

.social-media-input-container {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 3rem;
	height: 5rem;
	

	&:last-child {
		margin-bottom: 0;
	}

	&:first-of-type {
		margin-top: 3.3rem;
	}
}

.finish-modal-container {
	display: flex;
	width: 49rem;
	//height: 46rem;
	padding: 2rem;
}

.finish-modal-header {
	display: flex;
	padding-top: 2rem;
	padding-left: 2rem;
	width: 100%;
}

.close-finish-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	border: none;
	margin-left: auto;
	//margin-right: 2rem;
	top: 2rem;
	left: 43.5rem;
	width:3.6rem;
	height:3.6rem;
	color: $text-btn-color;
	background-color: $input-background;
}

.icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 7rem;
	height: 7rem;
	background-color: $status-active-light;
	border-radius: 50%;
	color: $status-active-base;
	flex-shrink: 0;

	&--error {
		@extend .icon-wrapper;
		background-color: $color-error-light;
		color: $color-error-base;
	}

	&--warning {
		@extend .icon-wrapper;
		background-color: $color-warning-light;
		color: $color-warning-base;
	}
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 4rem;
	padding-bottom: 7.5rem;
	color: $text-main-color;
	padding-left: 3rem;
	padding-right: 3rem;
	&--align-left {
		@extend .modal-content;
		padding-top: 1.7rem;
		align-items: flex-start;
		justify-content: center;
	}
}

.modal-text-container {
	padding-top: 2.6rem;
	padding-bottom: 3rem;
	font-size: 1.4rem;
}

.modal-btn {
	background-color: $blue-theme-base;
	border-radius: .5rem;
	border: none;
	padding: 1.7rem 3.2rem 1.6rem 3.2rem;
	color: white;
	font-size: 1.4rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.required-fields-text {
	font-size: 1.4rem;
}

.social-media-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.9rem;
	height: 4.9rem;
	background-color: $blue-theme-light;
	color: $blue-theme-base;
	border-radius: 50%;
	margin-right: 1rem;
}

.group-input-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -3rem;
	margin-right: 1rem;
	color: $text-btn-color;
}

.wrapped-input {
	height: 5rem;
	flex-grow: 1;
	border: none;
	border-radius: .5rem;
	background-color: $input-background;
	//margin-top: .8rem;
	padding: 1.6rem 4rem 1.6rem 1.6rem;
	color: $text-main-color;
	font-family: $font-regular;

	&:focus {
		@extend .wrapped-input;
		outline: none;
		border: .1rem solid $blue-theme-base;
	}
}

.wrapper-code-input {
	display: flex;
	height: 5rem;
	margin-top: 1rem;
	margin-bottom: .8rem;
	width: 100%;
	flex-direction: row;
	align-items: center;
	border: none;
	border-radius: .5rem;
	//background-color: $input-background;
	color: $text-main-color;
	font-family: $font-regular;

	&:focus {
		@extend .wrapper-code-input;
		outline: none;
		border: .1rem solid $blue-theme-base;
	}

	&--error {
		@extend .wrapper-code-input;
		border: .1rem solid $color-error-base;

		&:focus {
			@extend .wrapper-code-input--error;
		}
	}
}

.code-input {
	font-family: $font-regular;
	font-size: 1.4rem;
	background-color: $input-background;
	border: none;
	border-radius: .5rem;
	padding: 1.6rem;
	width: 100%;
	height: 5rem;

	&:disabled {
		opacity: 0.5;
		background-color: scale-color($input-background, $lightness: 5%)
	}

	&:focus {
		outline: none;
		border: .1rem solid $blue-theme-base;
	}

	&--error {
		@extend .code-input;
		border: .1rem solid $color-error-base;
		&:focus {
			@extend .code-input--error;
		}
	}
}

.prefix-input {
	display: flex;
	flex-grow: 1;
	align-items: center;
	font-family: $font-regular;
	font-size: 1.4rem;
	background-color: $outline-color;
	border-top-left-radius: .5rem;
	border-bottom-left-radius: .5rem;
	height: 5rem;
	padding: 0 1.6rem;
	&--hidden {
		display: none;
	}
}

.select-menu-item {
	font-family: $font-regular;
	font-size: 1.4rem;
}

.select-menu-item-hidden {
	display: none;
}

.avatar-input-container {
	display: flex;
	align-items: center;
}

.avatar-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18rem;
	height: 18rem;
	border-radius: 50%;
	border: .2rem solid $blue-theme-light;
	//padding: 1rem;
}

.placeholder-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16rem;
	height: 16rem;
	background-color: $blue-theme-light;
	border-radius: 50%;
	color: $blue-theme-base;
}

.avatar-img {
	width: 16rem;
	height: 16rem;
}

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

input.visually-hidden:focus + label,
input.visually-hidden:focus-within + label {
    outline: 8px solid rgba(86, 206, 239, .4);
}

.upload-avatar-label {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 3rem;
	height: 5rem;
	padding: 0 3.2rem 0 3.2rem;
	border: .1rem solid $outline-color;
	border-radius: .6rem;
	font-family: $font-medium;
	font-size: 1.4rem;
	color: $text-btn-color;
	cursor: pointer;

	&:hover {
		color: $blue-theme-base;
	}
}

.container__list {
	width: 100%;
}

.container__list-section {
	width: 100%;
	padding-bottom: 6.1rem;
}

.container__list-item {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 0;
	margin: .8rem 0;

	&--selected {
		@extend .container__list-item;
		background-color: $blue-theme-light;
		color: $blue-theme-base;
	}
}

.list-section__title {
	font-family: $font-bold;
	font-size: 1.8rem;
	margin-left: 1.6rem;
	margin-bottom: 3rem;
}

.list-item__text {
	font-family: $font-regular;
	font-size: 1.4rem;
	margin-right: auto;
	margin-left: 1.6rem;

	&--selected {
		@extend .list-item__text;
		font-family: $font-semibold
	}
}

.form__intro-text {
	font-size: 1.4rem;
	margin-left: 1.6rem;
}

.btn__save-edit {
	height: 5rem;
	width: 18rem;
	background-color: $blue-theme-base;
	border: none;
	border-radius: .5rem;
	color: white;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}

	&:disabled {
		opacity: 0.5;
		color: white;
	}
}

.modal-content-container {
	display: flex;
	flex-direction: column;
	width: 30rem;
	align-self: center;

}

.btn-container {
	width: 100%;
}

.modal-title {
	font-family: $font-semibold;
	font-size: 2.2rem;
	text-align: center;
	padding-bottom: 3rem;
	color: $text-main-color;

	&--align-left {
		@extend .modal-title;
		text-align: left;
	}
}

.btn-hidden {
	display: none;
}

.select-menu {
	max-height: 5.4rem;
	
}

.label-input {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: $input-background;
	height: 5.8rem;
	border: none;
	border-radius: .6rem;
	margin-top: 1rem;
	margin-bottom: 0.8rem;
	align-items: center;

	&--error {
		@extend .label-input;
		border: .1rem solid $color-error-base;
	}

}

.upload-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	border: .1rem solid $outline-color;
	border-radius: .6rem;
	padding: 1.6rem 3.2rem 1.6rem 3.2rem;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;
	margin: 0 1.6rem;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}

	.MuiButton-label {
		width: inherit;
	}
}

.filename-text {
	font-size: 1.4rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.cancel-upload-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 4rem;
	min-width:1rem;
	margin-left: auto;
	margin-right: 1.6rem;
	color: $text-btn-color;

	&:hover {
		background-color: $blue-theme-base;
		color: white;
	}

	.MuiButton-text {
		padding: 0;
	}
}

.cancel-icon {
	width: 2rem;
	height: 2rem;
}

.add-text {
	margin-left: 1.6rem;
}

.group-subtitle-container {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	height: 3.6rem;
	margin: 1rem 0;
}

.group-input-subtitle {
	font-size: 1.4rem;
	font-family: $font-semibold;
}

.container__checkbox-alt {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1rem 0;
}

.checkbox-alt {
	margin: 0;
	margin-right: -.3rem;
	margin-left: 1.6rem;
	padding: 0;
}

.checkbox-alt.MuiCheckbox-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.checkbox-alt .MuiSvgIcon-root {
	width: 	2.4rem;
	height: 2.4rem;
}

.input-btn {
	font-size: 1.4rem;
	font-family: $font-semibold;
}

.add-new-input-btn {
	display: flex;
	align-items: center;
	height: 4.2rem;
	border-radius: .4rem;
	border: .1rem solid $text-btn-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	padding: 1.6rem;
	margin-top: 5rem;
	cursor: pointer;
	text-transform: none;
	margin-bottom: 2rem;

	&:hover {
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
		background-color: white;
	}
}

.form__label-alt {
	font-family: $font-semibold;
	font-size: 1.2rem;
	display: block;
	&--error {
		@extend .form__label-alt;
		color: $color-error-base;
	}
}

.form__input-container-alt {
	margin-bottom: 2rem;
	&:focus-within {
		.form__label-alt {
			color: $blue-theme-base;
		}
	}
}