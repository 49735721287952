.container__login-page {
	display: flex;
	flex-direction: row;
	//background-image: url('../../img/backgroundImage.jpg');
	//background-size: cover;
	//background-position: top -100px left 19vw;
	//background-repeat: no-repeat;
	height: 100vh;
}

.header--login-page {
	display: flex;
	height: 11rem;
	width: 99%;
	border-bottom: .2rem solid $outline-color;
	align-items: center;
}

.header__logo-img {
	margin-left: 4.2em;
}

.modal__logo-img {
	margin-left: 6rem;
	align-self: center;
}

.form__title {
	font-family: $font-semibold;
	font-size: 3.6rem;
	margin-bottom: 5rem;
}

.container__login-content {
	display: flex;
	flex-direction: column;
	width: 55vw;
	align-items: center;
	background-color: white;
}

.container__form {
	margin-top: 8rem;
	width: 36rem;
}

.container__background-img {
	height: 100vh;
	width: 45vw;
}

.login__background-img {
	height: 100vh;
	width: 45vw;
	object-fit: fill;
}

.form__input-container {
	margin-bottom: 2rem;
	&:focus-within {
		.form__label {
				font-family: $font-semibold;
				font-size: 1.2rem;
				display: block;
				color: $blue-theme-base;
			&--error {
				@extend .form__label;
				color: $color-error-base;
			}
		}

		.label-input {
			border: .1rem solid $blue-theme-base;

			&--error {
				border: .1rem solid $color-error-base;
			}
		}
	}

	.MuiInput-root {
		width: 100%;
		margin-top: .8rem;
		border-radius: .5rem;
		border: none;
		padding: 0;

		&:before {
			border:none;
			
		}

		&:after {
			border: none;
			
		}
	}

	.MuiInput-input {
		padding: 0;
	}

	.MuiSelect-select {
		border-radius: .5rem;
		padding-left: 1.6rem;

		&:focus {
			background-color: $input-background;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	.MuiInputBase-input.Mui-disabled {
		opacity: 0.5;
	}

	.MuiSelect-icon {
		top: calc(50% - 1.4rem);
		right: 1.5rem;
		width: 2.4rem;
		height: 2.4rem;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border: none;
	}
}

.form__label {
	font-family: $font-semibold;
	font-size: 1.2rem;
	display: block;
	&--error {
		@extend .form__label;
		color: $color-error-base;
	}
}

.form__input {
	font-family: $font-regular;
	font-size: 1.4rem;
	margin-top: 1rem;
	margin-bottom: .8rem;
	background-color: $input-background;
	border: none;
	border-radius: .5rem;
	padding: 1.6rem;
	width: 100%;
	height: 5rem;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:disabled {
		opacity: 0.5;
	}

	&:focus {
		@extend .form__input;
		outline: none;
		border: .1rem solid $blue-theme-base;
	}

	&--error {
		@extend .form__input;
		border: .1rem solid $color-error-base;
		&:focus {
			@extend .form__input--error;
		}
	}
}

input[type=number] {
	-moz-appearance: textfield;
}

.form__input-password {
	@extend .form__input;
	padding-right: 4.5rem;

	&:disabled {
		opacity: 0.4;
	}

	&--error {
		@extend .form__input-password;
		border: .1rem solid $color-error-base;
		&:focus {
			@extend .form__input-password--error;
		}
	}
}

.btn__input-btn {
	margin-left: -4rem;
	vertical-align: middle;
	border: none;
	background-color: transparent;
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
}

.input__icon {
	color: $text-btn-color;
	width: 2.4rem;
	height: 2.4rem;
}

.input-feedback {
	display: flex;
	align-items: center;
	font-family: $font-semibold;
	font-size: 1.2rem;
	color: $color-error-base;

}

.btn__icon {
	color: white;
	width: 2rem;
	height: 2rem;
	vertical-align: middle;
}

.recaptcha {
	display: flex;
	justify-content: center;
}

.btn--forgot-password {
	background-color: white;
	border: none;
	font-family: $font-bold;
	font-size: 1.2rem;
	color: $blue-theme-base;
	margin-bottom: 3rem;
	cursor: pointer;

	&:hover {
		color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.btn__login-btn {
	font-family: $font-medium;
	font-size: 1.4rem;
	background-color: $blue-theme-base;
	height: 5rem;
	width: 36rem;
	border: none;
	border-radius: .5rem;
	color: white;
	margin-top: 3rem;
	text-transform: none;
	cursor: pointer;

	&:disabled {
		color: white;
		opacity: 0.5;
	}

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.tooltip {
	height: 3.2rem;
	background-color: $text-btn-color;
	padding: .9rem 2rem;
	font-size: 1.2rem;
	font-family: $font-regular; 
}

.checkbox {
	margin: 0;
	margin-right: 1.6rem;
	margin-left: -.3rem;
	padding: 0;
}

.checkbox.MuiCheckbox-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.checkbox .MuiSvgIcon-root {
	width: 	2.4rem;
	height: 2.4rem;
}

.container__checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 2rem;
}

.checkbox__text {
	font-size: 1.4rem;
}

.checkbox__link {
	border: none;
	background-color: transparent;
	font-family: $font-bold;
	font-size: 1.4rem;
	text-decoration: underline;
	cursor: pointer;
}

.modal {
	display: flex;
	align-items: center;
	width: 49rem;
	background-color: white;
	padding-bottom: 5rem;
	transition: none;
}

.modal__root {
	.MuiBackdrop-root {
		background-color: rgba(21, 27, 38, 0.85);
	}

	.MuiPaper-elevation24 {
		box-shadow: none;
	}
}

.modal__header {
	display: flex;
	align-items: flex-start;
	height: 11rem;
	width: 100%;
	border-bottom: .1rem solid $outline-color;
}

.btn__modal-btn--close {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	border: none;
	margin-left: auto;
	margin-right: 2rem;
	margin-top: 2rem;
	width:3.6rem;
	height:3.6rem;
	min-width: 2rem;
	color: $text-btn-color;
	background-color: $input-background;
	cursor: pointer;

	&:hover {
		background-color: $blue-theme-base;
		color: white;
	}
}

.btn__modal-icon {
	width: 2.5rem;
	height: 2.5rem;
	//color: $text-btn-color;

	&--close {
		@extend .btn__modal-icon;
		width: 2.4rem;
		height: 2.4rem;
	}
}

.modal__title {
	font-family: $font-bold;
	font-size: 2.4rem;
	margin-bottom: 2rem;
	color: $text-main-color;
}

.modal__form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 36rem;
	margin-top: 6rem;
}

.btn__modal-btn {
	height: 5rem;
	width: 13.5rem;
	background-color: $blue-theme-base;
	border-radius: .5rem;
	border: none;
	color: white;
	margin-top: 2rem;
	font-family: $font-semibold;
	font-size: 1.4rem;
	text-transform: none;
	cursor: pointer;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.modal__text {
	font-size: 1.4rem;
	margin-bottom: 1.5rem;
}

.container__error-message {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	background-color: $color-error-light;
	padding: 1.7rem;
	margin-top: 2rem;
	$font-family: $font-semibold;
	font-size: 1.2rem;
}

.container__error-text {
	display: flex;
	width: 100%;
	font-family: $font-semibold;
	padding-bottom: .2rem;
}

.error-text {
	display: flex;
	align-items: center;
	margin-left: .6rem;
}

.btn__loading {
	color: white;
	vertical-align: middle;
	&--alternative {
		margin-top: 2.5rem;
		color: $blue-theme-base;
		vertical-align: middle;
	}
}

.container__terms-text {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 2rem;
}

.terms-text {
	font-size: 1.4rem;
	font-family: $font-regular;
}

.terms-subtitle {
	padding-bottom: 1rem;
	padding-top: 1rem;
	font-size: 1.6rem;
}

.container__terms-page {
	display: flex;
	flex-direction: column;
	//height: calc(100vh - 12rem);
	flex-grow: 1;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
}

.terms-title {
	font-size: 3.4rem;
	font-family: $font-semibold;
	padding-bottom: 2rem;
}

.terms-list {
	padding-top:.5rem;
	padding-bottom:.5rem;
	padding-left: 1.5rem;
}

.terms-sublist-item {
	padding-left: 1.5rem;
}