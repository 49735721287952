.main-content {
	display: flex;
	flex-direction: column;
	padding: 5rem;
	width: 100%;
	flex-grow: 1;
}

.content {
	display: flex;
	flex-direction: row;
	//flex-grow: 1;
}

.left-content {
	flex-direction: column;
	width: 50%;
	flex-grow:1;
}

.right-content {
	display: flex;
	flex-direction: column;
	width: 50%;
	flex-grow: 1;
	margin-left: 6rem;
}

.instructions-container {
	background-color: $blue-theme-light;
	border-radius: .4rem;
	padding: 3rem;
	height: 48.2rem;
	overflow: auto;
	
	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;


		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $input-background;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem $input-background;
	}
}

.model-container {
	background-color: $input-background;
	border-radius: .5rem;
	height: 68rem;
}

.dropzone-area {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: .3rem dashed $outline-color-dark;
	border-radius: .4rem;
	flex-grow: 1;
	padding: 5rem;

	&--error {
		margin-bottom: .8rem;
	}
}

.step-control-container--center{
	justify-content: center;
}

.title-content {
	font-size: 2.4rem;
	font-family: $font-bold;
	margin-bottom: 3rem;
}

.model-list-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 6.6rem;
	&:first-child {
		border-top: .1rem solid $outline-color;
	}
	border-bottom: .1rem solid $outline-color;
}

.item-btn-container {
	display: flex;
	flex-direction: row;
	margin-left: auto;
}

.item-btn-icon {

}

.radio-btn {
	margin-right: 1.7rem;
}

.radio-btn svg {
	width: 2rem;
	height: 2rem;
}

.radio-btn.MuiRadio-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.model-label {
	font-size: 1.4rem;
}

.btn-item-text {
	font-size: 1.2rem;
}

.item-btn-icon {
	width: 2rem;
	height: 2rem;
	margin-right: 1.7rem;
}

.new-model-btn {
	margin-top: 2rem;
	margin-left: 0;
}

.create-model-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 2rem;
}

.create-model-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.create-model-title {
	font-family: $font-bold;
	font-size: 1.8rem;
}

.save-model-btn {
	margin-left: auto;
}

.create-model-form-container {
	display: flex;
	flex-direction: column;
	flex-grow:1;
	width: 100%;
	margin-top: 1.5rem;
	background-color: white;
	border-radius: .4rem;
	padding: 2rem;
	overflow-y: auto;
	max-height: 6rem * 9.5;
}

.create-model-form {
	overflow-y: auto;
	padding-right: 3rem;

	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;


		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $input-background;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem $input-background;
	}

	&--with-scroll {
		padding-right: 3rem;
	}
}

.form-info {
	width: 100%;
	padding: 3rem;
	background-color: $blue-theme-light;
	margin-bottom: 4rem;
}

.file-type {
	font-size: 1.6rem;
	font-family: $font-bold;
}

.association-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 1rem;
	background-color: $blue-theme-light;
	margin-top: 2rem;
	width: 100%;
}

.association-fields-container {
	width: 100%;
}

.association-fields {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 1rem;
}

.read-association-fields {
	height: 4.7rem;

	&:not(:last-child) {
		border-bottom: .1rem solid $outline-color;
	}
}

.left-column {
	min-width: 18rem;
}

.right-column {

}

.column-fields {
	padding-left: 1rem;
}

.form-info-text {
	font-size: 1.4rem;
}

.info-text-bold {
	font-size: 1.4rem;
	font-family: $font-bold;
}

.association-title {
	font-size: 1.4rem;
	font-family: $font-bold;
}

.association-text {
	font-size: 1.2rem;
}

.checkbox-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.checkbox-label {
	font-size: 1.4rem;
}

.checkbox-form {
	margin-left: auto;
}

.checkbox-form.MuiCheckbox-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.checkbox-form .MuiSvgIcon-root {
	width: 	2.4rem;
	height: 2.4rem;
}

.checkbox-form.MuiIconButton-root.Mui-disabled {
	opacity: .5;
}

.error-create-model {
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-error {
	display: flex;
	flex-direction: column;
	justify-content: center; 
	margin-top: 1rem;
	width: 70%;
}

.text-error-title {
	font-size: 1.6rem;
	font-family: $font-bold;
	margin-bottom: 1rem;
	text-align: center;
}

.text-error {
	font-size: 1.4rem;
	font-family: $font-semibold;
	margin-top: .5rem;
	margin-bottom: .5rem;
	text-align: justify;
}

.error-create-icon {
	height: 12rem;
	width: 12rem;
	background-color: transparent;
	color: $outline-color-dark;
	font-size: 180rem;
}

.bulletpoint {
	font-size: 1rem;
	font-family: $font-regular;
}

.preview-table-container {
	background-color: $input-background;
	border-radius: .5rem;
	padding: 2rem;
	width: 100%;
	height: fit-content;
}

.preview-table {
	background-color: white;
	border-radius: .5rem;

	.MuiTableContainer-root {
		&::-webkit-scrollbar {
			width: .9rem;
			height: .9rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $blue-theme-base;
			border-radius: 5rem;


			&:hover {
				background-color: scale-color($blue-theme-base, $lightness: -20%);
			}
		}

		&::-webkit-scrollbar-track {
			background-color: $input-background;
			border-radius: 1rem;
			box-shadow: inset .7rem 1rem 1.2rem $input-background;
		}
	}
}



.model-list-container {
	margin-bottom: 2rem;
}

.first-column {
	background-color: scale-color($outline-color, $lightness: +75%);
	border-bottom: .1rem solid $outline-color;
	padding: 2rem 3rem;
}

.first-line {
	border-top-left-radius: .5rem;
}

.last-line {
	border-bottom-left-radius: .5rem;
	border-bottom: none;
}

.table-cell {
	border-bottom: .1rem solid $outline-color;
}

.table-cell-last-line {
	border-bottom-left-radius: .5rem;
	border-bottom: none;
}

.first-column-text {
	font-size: 1.4rem;
	font-family: $font-semibold;
}

.table-cell-text {
	font-size: 1.2rem;
	font-family: $font-regular;
}

.result-text-success {
	font-size: 1.2rem;
	color: $status-active-base;
}

.result-text-error {
	font-size: 1.2rem;
	color: $color-error-base;
}

.result-first-column {
	width: 20rem;
}

.results-message-container {
	display: flex;
	padding: 2rem;
	background-color: $status-active-light;
	border-radius: .5rem;
	width: 100%;
	margin-bottom: 2rem;
	flex-direction: row;
	align-items: center;

	&--error {
		background-color: $color-error-light;
	}

	&--warning {
		background-color: $color-warning-light;
	}
}

.content-results {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.results-message-text {
	font-size: 1.4rem;
	font-family: $font-semibold;
	margin-left: 1rem;
}

.results-loading {
	color: $blue-theme-base;
	vertical-align: middle;
}

.text-loading {
	margin-top: 2rem;
	font-size: 2.2rem;
	color: $blue-theme-base;
}

.importError-text {
	font-size: 1.4rem;
	font-family: $font-regular;
	margin-bottom: .2rem;
}