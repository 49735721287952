.tablepage-container {
	display: flex;
	flex-direction: column;
	//height: calc(100vh - 12rem);
	flex-grow: 1;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
	background-color: $input-background;

	::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;

		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem #fff;
	}
}

.tablepage-header {
	display:flex;
	align-items: center;
	width: 100%;
	margin-bottom: 3.2rem;
}

.header-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tablepage-title {
	font-family: $font-bold;
	font-size: 3.4rem;
}

.tablepage-subtitle {
	font-size: 1.2rem;
	margin-top: .6rem;
}

.tablepage-create-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	//width: 18.6rem;
	padding: 0 3.2rem 0 3.2rem;
	height: 5rem;
	background-color: $blue-theme-base;
	border: none;
	border-radius: .6rem;
	color: white;
	font-family: $font-medium;
	font-size: 1.4rem;
	margin-left: auto;
	cursor: pointer;
	text-transform:  none;
	align-self: flex-end;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.tablepage-content {
	border: .1rem solid $outline-color;
	border-radius: .6rem;
	background-color: white;

	::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;

		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem #fff;
	}

	.rdt_Table {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	header {
		height: auto;
		padding: 0;
		border-bottom: none;

		.MuiPaper-elevation1 {
			box-shadow: none;
		}

		.MuiAccordionSummary-content.Mui-expanded {
			margin: 1.8rem 0;
		}

		.MuiAccordionSummary-content {
			margin: 1.8rem 0;
		}

		.MuiIconButton-edgeEnd {
			margin-right: 0;
		}
	}

	.rdt_TableHeadRow {
		height: 6.7rem;
		background-color: rgba(232, 236, 238, 0.25);
		border-bottom: .1rem solid $outline-color;
		padding-left: 0;
		font-family: $font-semibold;
		font-size: 1.4rem;
  }

  .rdt_TableCol_Sortable {
  	.__rdt_custom_sort_icon__ {
  		padding-left: 1.3rem;
  	}
  }

  .rdt_TableRow {
  	&:hover {
  		color: $blue-theme-base;
      background-color: $blue-theme-light;
      transition-duration: 0.15s;
      transition-property: background-color;

      .cell__btn {
      	color: $blue-theme-base;
      }

      .cell__btn--menu {
      	color: $blue-theme-base;
      	border: .1rem solid $blue-theme-base;
      }

      .table-link {
      	color: $blue-theme-base;
      }
  	}
  }

  .rdt_Pagination {
  	padding-right: 3rem;

  	#pagination-first-page, #pagination-next-page, #pagination-previous-page, #pagination-last-page {
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		border: .1rem solid $outline-color;
  		margin: 0 0 0 .6rem;
  		height: 3.6rem;
  		width: 3.6rem;

  		&:hover:not(:disabled) {
  			color: white;
  			background-color: $blue-theme-base;
  		}
  	}
  }

  .MuiAccordionSummary-root:hover:not(.Mui-disabled) {
  	cursor: default;
  }

}

	.table-full-width {
		width: 100%;
	}

.table-no-highlight-hover {
	.rdt_TableRow {
		&:hover {
			border-top: .1rem solid $outline-color;
			border-bottom: .1rem solid $outline-color;
			background-color: white;
			color: $text-main-color;

			.cell__btn {
      	color: $text-btn-color;

      	&:hover {
      		color: $blue-theme-base;
      	}
      }
		}
	}
}

.accordion__tableHeader {
	width: 100%;
}

.accordion__summary-table {
	border-bottom: .1rem solid $outline-color;
}

.accordion__details-table {
	padding: 1.5rem 0 1.5rem 4.8rem;
	background-color: rgba(232, 236, 238, 0.25);
	overflow-x:hidden;
	width: 100%;

	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;

		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem #fff;
	}


	&--users {
		@extend .accordion__details-table;
		padding-left: 10rem;
	}
}

.tablepage-content .MuiCheckbox-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.tablepage-content .MuiSvgIcon-root {
	width: 	2.4rem;
	height: 2.4rem;
}

.tablepage-content .MuiSvgIcon-root.icon-pagination {
		width: 1.8rem;
		height: 1.8rem;
	}

.tablepage-subheader {
	display: flex;
	width: 100%;
}

.multiaction-menu-btn {
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 4.2rem;
	width: 20rem;
	border-radius: .4rem;
	border: .1rem solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	cursor: pointer;
	text-transform: none;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}

	&--open {
		@extend .multiaction-menu-btn;
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
	}
}

.export-menu-btn {
	display: flex;
	align-items: center;
	height: 4.2rem;
	border-radius: .4rem;
	border: .1px solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	padding: 1.6rem;
	margin: 0 0 0 1rem;
	cursor: pointer;
	text-transform: none;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}

	&--open {
		@extend .export-menu-btn;
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
	}

}


.validate-menu-list {
	padding: 0; /* Remove o padding do menu */
  }

.validate-menu-paper {
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.validate-menu-item {
	font-family: $font-regular;
	font-size: 1.4rem;
	width: 14rem;
	height: 3rem;
	gap: 0.8rem;
	border-bottom: 1px solid #E0E0E0;
	padding: 0;
	padding-left:1.6rem;
	padding-bottom:1.2rem;
	padding-top:1.2rem;
	color: #292929;

	&:hover {
		color: $blue-theme-base;
		background-color: $blue-theme-light;
	}
}

.modal-button-div{
	display: flex;
	flex-direction: column;
	gap: 1.5rem
}

.modal-validate-paper{
	width: 49rem;
	height: 44.5rem;
	padding: 3.2rem;
}

.modal-validate-title{
	font-family: $font-semibold;;
	font-size: 2.4rem;
	color: $text-main-color;
}

.modal-validate-subtitle{
	padding-top: 5rem;
	padding-bottom: 5rem;
	font-family: $font-regular;;
	font-size: 1.4rem;
	color: $text-main-color;
}

.modal-validate-btn{
 width: 37rem;
 height: 5rem;
 color: #FFFFFF;
 background-color: $blue-theme-base;
 border-radius: 0.5rem;
 font-family: $font-medium;;
	font-size: 1.4rem;
	text-transform: none;
}

.modal-cancel-btn{
	width: 37rem;
 height: 5rem;
 color: $blue-theme-base;
 background-color: #FFFFFF;
 border:1px solid $blue-theme-base;
 border-radius: 0.5rem;
 font-family: $font-medium;;
 font-size: 1.4rem;
 text-transform: none;
}

.validate-menu-btn {
	display: flex;
	align-items: center;
	height: 3.6rem;
	width: 3.6rem;
	border-radius: .4rem;
	border: .1px solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	cursor: pointer;
	text-transform: none;

	&.MuiButton-root {
		min-width: 0;
	}

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}

	&--open {
		@extend .export-menu-btn;
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
	}

}

.btn__control-text {
	padding-left: 1.8rem;
}

.btn__text-inverted {
	padding-right: 1.8rem;
}

.export-menu-container {
	width: 15rem;
	margin-top: .8rem;
	border: .1rem solid $outline-color;
	box-shadow: 0 2.5rem 6rem $outline-color;

	.MuiList-padding {
		padding: 0;
	}
}

.filter-menu-btn {
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 4.2rem;
	width: 9.7rem;
	border-radius: .4rem;
	border: .1px solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	margin-left: 1.2rem;
	padding: 0 1.3rem 0 1rem;
}

.filter-menu-container {
	width: 25rem;
	max-height: 4.2rem * 6.4;
	margin-top: .8rem;
	border: .1rem solid $outline-color;
	box-shadow: 0 2.5rem 6rem $outline-color;

	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;

		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem white;
	}

	.MuiList-padding {
		padding: 0;
	}
}

.table-controls-container {
	display: flex;
	flex-direction: row;
	margin-left: auto;
}

.export-menu-item {
	font-size: 1.4rem;
	padding-left: 2rem;
	padding-right: 2rem;
	border-bottom: .1rem solid $outline-color;
	height: 5.4rem;
	color: $text-main-color;
	cursor: pointer;

	&:hover {
		background-color: $blue-theme-light;
		color: $blue-theme-base;
	}
}

.btn-icon-wrapper {
	display: flex;
	padding-right: 1rem;
}

.status-active {
	color: $status-active-base;
	background-color: $status-active-light;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;
}

.status-inactive {
	color: $text-btn-color;
	background-color: $outline-color;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;
}

.status-pending {
	color: $status-pending-base;
	background-color: $status-pending-light;
	padding: .8rem 1.4rem .8rem 1.4rem;
	border-radius: 15rem;
}


.status-error {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-error-base;
	background-color: $color-error-light;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;
	border: none;
	font-size: 1.4rem;
	line-height: normal;
	line-spacing: 0;
	font-family: $font-regular;
	cursor: pointer;
	text-transform: none;

	&:hover {
		background-color: scale-color($color-error-base, $lightness: -20%);
		color: white;
	}
}

.status-invalid {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-error-base;
	background-color: $color-error-light;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;
	border: none;
	font-size: 1.4rem;
	line-height: normal;
	line-spacing: 0;
	font-family: $font-regular;
	cursor: pointer;
	text-transform: none;
}

.cell__btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	border: none;
	color: $text-btn-color;

	&--menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 4.2rem;
		background-color: white;
		border: .1rem solid $outline-color;
		border-radius: .4rem;
		padding: 0 1.6rem 0 1.6rem;
		cursor: pointer;
	}

}

.cell__btn--active {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 4.2rem;
		background-color: white;
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
		border-radius: .4rem;
		padding: 0 1.6rem 0 1.6rem;
		cursor: pointer;
}

.btn__text {
	font-family: $font-regular;
	font-size: 1.4rem;
	text-decoration: underline;
	margin-right: 1.85rem;
	cursor: pointer;
}

.modal__item {
	display: flex;
	align-items: center;
	padding: 1rem 0;
	margin: .8rem 0;

	&--selected {
		@extend .modal__item;
		background-color: $blue-theme-light;
		color: $blue-theme-base;
	}
}

.container__modal-header {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.content-modal {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	padding: 4rem 7rem;
	color: $text-main-color;

	&--larger-content {
		padding: 4rem 2rem;
	}
}

.btn__close {
  display: flex;
  border-radius: 2rem;
  border: none;
  margin-left: auto;
  width: 3.6rem;
  height: 3.6rem;
  color: $text-btn-color;
  background-color: $input-background;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
		background-color: $blue-theme-base;
		color: white;
	}
}

.modal__body {
	width: 61rem;
}

.modal__body-alt {
	width: 110rem;
	max-width: 110rem;
	height: 80rem;
}

.dialog__title {
	padding: 2rem 2rem 2rem 4rem;
}

.modal__header-title {
	font-family: $font-semibold;
	font-size: 3.4rem;
	color: $blue-theme-base;
	//padding: 0 1.6rem;
}

.modal__content {
	padding: 2rem 4rem .3rem 4rem;
}

.modal__content-alt {
	padding: 2rem 4rem 5rem 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pdfobject-container {
	display: flex;
	width: 100%;
	height: 100%;
	border: 3.3rem solid $input-background;
}

.modal__category-title {
	font-family: $font-bold;
	font-size: 1.8rem;
	padding: 0 1.6rem;
}

.modal__item-list-container {
	padding-top: 2rem;
	padding-bottom: 6.1rem;
}

.modal__item-text {
	font-family: $font-regular;
	font-size: 1.4rem;
	margin-right: auto;
	margin-left: 1.6rem;
	&--selected {
		@extend .modal__item-text;
		font-family: $font-semibold;
	}
}

.checkbox--right {
	margin: 0 1.6rem 0 0;
	padding: 0;
}

.checkbox--right.MuiCheckbox-colorSecondary.Mui-checked {
	color: $blue-theme-base;
}

.checkbox--right .MuiSvgIcon-root {
	width: 	2.4rem;
	height: 2.4rem;
}

.search-input-column {
	display: flex;
	flex-grow: 1;
	flex-basis: 0;
	flex-shrink: 0;
	//min-width: 23.5rem;
	//padding: 0 1.6rem 0 1.6rem;
	align-items: center;

	&--hidden {
		@extend .search-input-column;
		visibility: hidden;
	}
}

.container-search-column {
	display: flex;
	flex-grow: 1;
	min-width: 24.5rem;
	padding: 0 1.6rem 0 1.6rem;

	&--hidden {
		@extend .container-search-column;
		display: none;
	}
}

.search-input {
	max-width: 20rem;
	border: none;
	height: 4.2rem;
	border: .1rem solid $outline-color;
	border-radius: .4rem;
	padding-left: 3.2rem;
	padding-right: 1rem;
	font-family: $font-regular;
	font-size: 1.4rem;
	color: $text-btn-color;
	&:focus {
		@extend .search-input;
		outline: none;
		border: .1rem solid $blue-theme-base;
	}
}

.icon__search-input {
	width: 1.3rem;
	height: 1.3rem;
	color: $text-btn-color;
	margin-right: -3rem;
	background-color: transparent;
	z-index: 2;
}

.modal__multiAction {
	width: 49rem;
	padding: 2rem;
}

.modal__backdrop {
	.MuiBackdrop-root {
		background-color: rgba(21, 27, 38, 0.75);
	}

	.MuiPaper-elevation24 {
		box-shadow: none;
	}
}

.title-modal {
	font-family: $font-bold;
	font-size: 2.4rem;
	margin-bottom: 3rem;
	color: $text-main-color;
	text-align: center;
}

.btn-confirm {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	padding: 1.6rem 5rem 1.6rem 5rem;
	background-color: $blue-theme-base;
	border-radius: .6rem;
	border: none;
	color: white;
	font-family: $font-medium;
	font-size: 1.4rem;
	width: 100%;
	margin-top: 1.6rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}

}

.btn-cancel {
	@extend .btn-confirm;

	color: $blue-theme-base;
	background-color: white;
	border: .1rem solid $blue-theme-base;

	&:hover {
		background-color: scale-color($blue-theme-light, $lightness: 10%);
	}
}

.message__container {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.nodata-content-icon {
	height: 12rem;
	width: 12rem;
	background-color: $blue-theme-light;
	color: $blue-theme-base;
	font-size: 18rem;
	margin-right: 1rem;
}

.title-message {
	font-size: 3.2rem;
	color: $blue-theme-base;
	line-height: 5.8rem;
}

.progress-loading {
	color: $blue-theme-base;
	margin-right: 1rem;
}

.progress-export-loading {
	color: $text-btn-color;
	margin-left: 3rem;
	margin-right: 3rem;
}

.loading-text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.loading-text {
	font-family: $font-semibold;
	color: $blue-theme-base;
	font-size: 3.2rem;
	margin-right: 1rem;
}

.error-loading-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 4rem;
	margin-left: 4rem;
}

.error-loading-text {
	font-family: $font-semibold;
	color: $blue-theme-base;
	font-size: 3.2rem;
	margin-left: 3rem;
}

.cell-container {
	display:flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
}

.cell-menu {
	margin-top: .8rem;
	border: .1rem solid $outline-color;
	box-shadow: 0 2.5rem 6rem $outline-color;
}

.cell-menu-item {
	font-size: 1.4rem;
	padding-left: 2rem;
	padding-right: 2rem;
	height: 4.2rem;
	color: $text-main-color;
	cursor: pointer;

	&:hover {
		background-color: $blue-theme-light;
		color: $blue-theme-base;
	}
}

.cell-menu-icon {
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
}

.container-error-log {
	padding: 2rem;
	background-color: $outline-color;
	margin-bottom: 2rem;
}

.log-error-table {
	background-color: white;
	border-radius: .5rem;
}

.calendar-btn {
	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 4.2rem;
	width: 15.6rem;
	border-radius: .4rem;
	border: .1rem solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	cursor: pointer;
	text-transform: none;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}

	&--open {
		@extend .calendar-btn;
		color: $blue-theme-base;
		border: .1rem solid $blue-theme-base;
	}

}

//calendar styles

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker {
  font-family: $font-regular;
  font-size: 1.2rem;
  background-color: #fff;
  color: $text-btn-color;
  border: .1rem solid $outline-color;
  border-radius: .6rem;
  display: inline-block;
  position: relative;
  margin-left: 1rem;
}

.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time,
  .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.react-datepicker__triangle {
  //position: absolute;
  //left: 50px;
}

.react-datepicker-popper {
  z-index: 1;

  &[data-placement^="bottom"] {
   // padding-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      //@extend %triangle-arrow-up;
    }
  }

  &[data-placement="bottom-end"],
  &[data-placement="top-end"] {
    .react-datepicker__triangle {
      left: auto;
      right: 50px;
    }
  }

  &[data-placement^="top"] {
    padding-bottom: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      //@extend %triangle-arrow-down;
    }
  }

  &[data-placement^="right"] {
    padding-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^="left"] {
    padding-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  background-color: white;
  padding: 2.5rem 0 0 0;
  position: relative;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;

    &:not(&--only) {
      border-top-left-radius: 0;
    }
  }

  &:not(&--has-time-select) {
    border-top-right-radius: $datepicker__border-radius;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: $text-btn-color;
 	font-family: $font-regular;
  font-size: 1.2rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: .9rem;
  padding: 0;
  border: none;
  z-index: 1;
  height: 2rem;
  width: 2rem;
  text-indent: -999em;
  //overflow: hidden;

  &--previous {
    left: 3rem;
  }

  &--next {
    right: 3rem;

    &--with-time:not(&--with-today-button) {
      right: 85px;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
    }

    &-upcoming {
      top: -4px;
    }
  }

  &:hover {
    *::before {
      border-color: darken($datepicker__muted-color, 15%);
    }
  }
}

.react-datepicker__navigation-icon {
  position: relative;
  top: 1rem;
  font-size: 2rem;
  width: 0;

  &::before {
    @extend %navigation-chevron;
  }

  &--next {
    left: -2px;

    &::before {
      transform: rotate(45deg);
      left: -7px;
    }
  }

  &--previous {
    right: -2px;

    &::before {
      transform: rotate(225deg);
      right: -7px;
    }
  }
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: $datepicker__margin;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__month {
  margin: 1.5rem;
  text-align: center;

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    height: 3.5rem;
    border: .1rem solid $outline-color;
    border-radius: .4rem;
    margin: .5rem;
  }
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;

  .react-datepicker-time__caption {
    display: inline-block;
  }

  .react-datepicker-time__input-container {
    display: inline-block;

    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;

      input {
        width: auto;
      }

      input[type="time"]::-webkit-inner-spin-button,
      input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="time"] {
        -moz-appearance: textfield;
      }
    }

    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 85px;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;

    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;

      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }

          &--selected {
            background-color: $blue-theme-light;
            color: $text-btn-color;

            &:hover {
              background-color: white;
              color: $blue-theme-base;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;

  &.react-datepicker__week-number--clickable {
    cursor: pointer;

    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__month-wrapper {
	display: flex;
	flex-direction: row;
}

.react-datepicker__month,
.react-datepicker__quarter {
  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--disabled {
    color: $datepicker__muted-color;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius: .4rem;
    background-color: $blue-theme-light;
  }

  &--today {
    //font-weight: bold;
  }

  &--highlighted {
    border-radius: .4rem;
    background-color: $blue-theme-light;
    color: $outline-color;

    &:hover {
      //background-color: darken($datepicker__highlighted-color, 5%);
      background-color: $blue-theme-light;
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: .4rem;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: .4rem;
    background-color: white;
    color: $blue-theme-base;
    border: .1rem solid $blue-theme-base !important;

    /*&:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }*/
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: white;
      color: $blue-theme-base;
    }
  }

  &:hover {
    background-color: $blue-theme-light;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: none;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;
  position: relative;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %navigation-chevron;

    transform: rotate(135deg);
    right: -16px;
    top: 0;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;

  &::after {
    cursor: pointer;
    background-color: $datepicker__selected-color;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }
}



.btn-deadline-input {
	display: flex;
	align-items: center;
	padding: 0 1.6rem;
	height: 4.2rem;
	width: 21rem;
	border-radius: .4rem;
	border: .1rem solid $outline-color;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-regular;
	font-size: 1.2rem;
	margin-top: .8rem;
	margin-bottom: .6rem;
	text-transform: none;
	justify-content: space-between;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
		border: .1rem solid $blue-theme-base;
	}
}

.deadline-container {
	align-items: center;
	margin-bottom: 3rem;
	width: 21rem;

	.react-datepicker {
		margin-left: 0;
		width: 21rem;
		align-items: center;
	}

	.react-datepicker__month-container {
		float: none;
	}

	.react-datepicker__day--outside-month {
		visibility: hidden;
	}

	.react-datepicker__day-names {
		display: none;
	}

	.react-datepicker__day {
		width: 2.2rem;
		line-height: 2.2rem;
	}

	.react-datepicker__day--selected {
		background-color: $blue-theme-base;
		color: white;
		border-radius: 50%;
	}

	.react-datepicker__header {
		padding: 0;
	}

	.react-datepicker__day--keyboard-selected {
		border-radius: 50%;
	}
}

.content-container-modal {
	display: flex;
	flex-direction: column;
	padding-bottom: 5rem;
	align-items: center;
}

.header-deadline {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 0 1rem;
	justify-content: space-between;
}

.deadline-title {
	font-size: 1.2rem;
	font-family: $font-semibold;
}

.deadline-select-container {

	.MuiInput-root {
		width: 100%;
		margin-top: .8rem;
		border-radius: .5rem;
		border: none;
		padding: 0;

		&:before {
			border:none;
			
		}

		&:after {
			border: none;
			
		}
	}

	.MuiInput-input {
		padding: 0;
	}

	.MuiSelect-select {
		border-radius: .5rem;
		padding-left: 1.6rem;

		&:focus {
			background-color: $input-background;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	.MuiInputBase-input.Mui-disabled {
		opacity: 0.5;
	}

	.MuiSelect-icon {
		top: calc(50% - 1rem);
		right: 1rem;
		width: 2rem;
		height: 2rem;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border: none;
	}
}

.deadline-select-month {
	display: flex;
	align-items: center;
	width: 8rem;
	height: 3rem;
	background-color: white;
	border: .1rem solid $outline-color;
	font-family: $font-regular;
	font-size: 1rem;
	color: $text-btn-color;
}

.deadline-select-year {
	display: flex;
	align-items: center;
	width: 6.5rem;
	height: 3rem;
	background-color: white;
	border: .1rem solid $outline-color;
	font-family: $font-regular;
	font-size: 1rem;
	color: $text-btn-color;
	//margin-bottom: 0.8rem;
}

.arrow-deadline {
	width: 2rem;
	height: 2rem;
}

.align-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.table-cell-title {
	background-color: rgba(232, 236, 238, 0.25);
}

.table-title-text {
	font-size: 1.4rem;
	font-family: $font-semibold;
}

.table-row-winner {
	background-color: $blue-theme-light;
}

.table-cell-winner {
	font-size: 1.2rem;
	font-family: $font-regular;
	color: $blue-theme-base;
}

.table-modal-text {
	font-size: 1.2rem;
	font-family: $font-regular;
}

.table-link {
	color: $text-btn-color;
	font-family: $font-regular;
}

.multiStatus {
	text-align: center;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;

	&--CE, &--CR {
		color: $status-active-base;
		background-color: $status-active-light;
	}

	&--RS, &--ER {
		color: $color-warning-base;
		background-color: $color-warning-light;
	}
}

.cert-period-title {
	text-align: left;
	font-size: 1.2rem;
	font-family: $font-semibold;
}

.cert-picker-container {
	.react-datepicker {
		margin-left: 0;
		border-radius: 0;
	}
}