/*====================================================================*/
/*  Typography
/*====================================================================*/

@font-face {
	font-family: 'Inter-Regular';
	src: url('../../fonts/inter/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter-Medium';
	src: url('../../fonts/inter/Inter-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter-SemiBold';
	src: url('../../fonts/inter/Inter-SemiBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter-Bold';
	src: url('../../fonts/inter/Inter-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Bold';
	src: url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/*====================================================================
/*  General
====================================================================*/

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-family: $font-regular;
	color: $text-main-color;
	height: 100%;
	font-size: 62.5%;

	@include respond(general-screen) {
		font-size: 56.25%;
	}

	@include respond(medium-screen) {
		font-size: 50%;
	}

	@include respond(large-screen) {
		font-size: 75%;
	}
}

body {
	box-sizing: border-box;
	height: 100%;
}