.content-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 5rem;
	background-color: $input-background;
	align-items: center;
	background-color: white;
	border-radius: .6rem;
	border: .1rem solid $outline-color;
}

.info-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: $blue-theme-light;
	//margin: 5rem 5rem 0 5rem;
	padding: 3rem;
	border-radius: .6rem;
	margin-bottom: 3rem;
}

.container-import-icon {
	width: 10rem;
	height: 10rem;
	background-color: $blue-theme-light;
	margin-bottom: 2rem;
}

.import-icon {
	font-size: 5rem;
	color: $blue-theme-base;
}

.info-title {
	font-size: 1.6rem;
	font-family: $font-bold;
	margin-bottom: 2rem;
}

.info-text {
	font-size: 1.4rem;
	font-family: $font-regular;
	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.import-btn-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-left: auto;
	justify-content: center;
	padding: 2rem 0;
}

.import-btn {
	width: 37rem;
	margin: 0;
}

.margin-left {
	margin-left: 2.5rem;
}

.import-btn-text {
	margin-left: 1rem;
}

.dropzone-container {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.file-dropzone {
	width: 100%;
	height: 54rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: .3rem dashed $outline-color-dark;
	border-radius: .4rem;
}

.text-container {
	width: 60%;
	text-align: center;
	margin-bottom: 2rem;
}

.dropzone-text {
	font-size: 1.4rem;
}

.dropzone-second-text {
	font-size: 1.4rem;
	margin-top: 2rem;

	&--file-zone {
		color: $text-btn-color;
		opacity: .5;
	}
}

.search-icon {
	width: 2rem;
	height: 2rem;
}

.file-list-container {
	width: 65%;
	margin-left: 3rem;
	display: flex;
	flex-direction: column;
	border: .1rem solid $outline-color;
	border-radius: .4rem;
	padding: 3rem;

	&--no-file {
		align-items: center;
		justify-content: center;
	}
}

.loading-file-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	border: .1rem solid $outline-color;
	border-radius: .4rem;
	padding: 3rem;

}

.response-container {
	margin-left: auto;
}

.response-text {
	font-size: 1.4rem;
	color: $status-active-base;

	&--error {
		color: $color-error-base;
	}
}

.file-error-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.container-file-list-icon {
	width: 10rem;
	height: 10rem;
	background-color: $input-background;
	color: $outline-color-dark;
	opacity: .5;
}

.file-list-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4rem;
}

.file-header-btn {
	margin-left: auto;
}

.header-text {
	font-size: 2rem;
}

.clear-list-btn-text {
	margin-left: 1rem;
}

.file-list {
	//margin-top: 4rem;
	overflow-y: auto;
	max-height: 8rem * 4.8;

	&::-webkit-scrollbar {
		width: .9rem;
		height: .9rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $blue-theme-base;
		border-radius: 5rem;


		&:hover {
			background-color: scale-color($blue-theme-base, $lightness: -20%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $input-background;
		border-radius: 1rem;
		box-shadow: inset .7rem 1rem 1.2rem $input-background;
	}

	&--with-scroll {
		padding-right: 3rem;
	}

}

.file-item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 8rem;
	border: .1rem solid $outline-color;
	border-radius: .4rem;
	padding: 0 3rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&:hover {
		border: .1rem solid $blue-theme-base;
		color: $blue-theme-base;

		.delete-btn {
			display: flex;
		}
	}
}

.file-item-text-container {
	display: flex;
	align-items: center;
	width: 100%;
}

.file-item-text {
	font-size: 1.6rem;
}

.file-item-icon-container {
	background-color: $blue-theme-light;
	width: 4.2rem;
	height: 4.2rem;
	margin-right: 1.2rem;
}

.file-item-icon {
	width: 1.6rem;
	height: 1.6rem;
	color: $blue-theme-base;
}

.delete-btn {
	margin-left: auto;
	text-transform: none;
	display: none;
	align-items: center;

	&:hover {
		background-color: white;
	}
}

.try-again-btn-text {
	font-family: $font-regular;
	font-size: 1.2rem;
}

.try-again-btn {
	text-transform: none;
	color: $blue-theme-base;
	margin-left: 1rem;

	&:hover {
		background-color: $blue-theme-base;
		color: white;
	}
}

.delete-btn-text {
	font-family: $font-regular;
	font-size: 1.2rem;
	color: $blue-theme-base;
	margin-left: 1.3rem;
}

.delete-btn-icon {
	color: $blue-theme-base;
	margin-left: auto;
}

.loading-icon-btn {
	color: white;
}

.loading-icon-item {
	color: $blue-theme-base;
}

.instructions-title {
	font-size: 1.4rem;
	font-family: $font-bold;
	margin-bottom: 2rem;
}

.instructions-text {
	font-size: 1.4rem;
	margin-bottom: 2.5rem;
}

.table-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: .5rem;
	border: .1rem solid $outline-color;
}

.table-row {
	height: 3.6rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2rem;

	&:first-child {
		background-color: $blue-theme-base;
		border-top-left-radius: .5rem;
		border-top-right-radius: .5rem;
		color: white;
	}

	&:not(:first-child) {
		background-color: white;
	}

	&:not(:last-child) {
		border-bottom: .1rem solid $outline-color;
	}

	&:last-child {
		border-bottom-left-radius: .5rem;
		border-bottom-right-radius: .5rem;
	}
}

.table-column {
	flex-grow: 1;
	font-size: 1.2rem;
}

.button-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text-modal {
	font-size: 1.6rem;
	font-family: $font-regular;
}

.loading-percentage {
	position: relative;
	margin-bottom: 2rem;
}

.loading-percentage-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0.5rem;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading-percentage-text {
	font-size: 1.4rem;
	font-family: $font-regular;
}

.loading-percentage-number {
	font-size: 1.6rem;
	font-family: $font-bold;
	color: $blue-theme-base;
}

.loading-table-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}