.detail-container {
	width: 58rem;
}

.detail-table-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 5rem;
	width: 58rem;
}

.detail-column {
	display: flex;
	flex-direction: column;
	padding: 1.5rem 0;
}

.detail-cell {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 8rem;
	padding: 0 1rem;
	min-width: 18rem;
}

.detail-title-container {
	display:flex;
	justify-content: center;
	margin-bottom: 3rem;
	text-align: center;
}

.detail-title {
	font-size: 3.4rem;
	font-family: $font-bold;
}

.detail-subtitle {
	font-family: $font-bold;
	font-size: 1.6rem;
}

.detail-info {
	font-family: $font-regular;
	font-size: 1.6rem;
}

.skeleton-info-txt {
	display: flex;
	height: 5rem;
	width: 30rem;
	min-width: 10rem;
	//margin-left: 1.8rem;
	background-color: $sidebar-icon;
}

.skeleton-info-title {
	display: flex;
	height: 8rem;
	width: 30rem;
	min-width: 10rem;
	background-color: $sidebar-icon;
}

.fiscal-table-container {
	width: 100%;
}

.table-no-border {
	border: none;
	border-radius: 0;

	.rdt_TableRow {
		&:hover {
			background-color: white;
			color: $text-main-color;

			.cell__btn {
      	color: $text-btn-color;

      	&:hover {
      		color: $blue-theme-base;
      	}
      }
		}
	}

}

.table-header-no-padding {
	padding: 1.5rem 0 0 0;
}

.table-search-width {
	min-width: 0;
}

.search-btn-margin {
	margin-left: auto;
}

.table-btn-text {
	font-family: $font-regular;
	font-size: 1.2rem;
	margin-left: 1.3rem;
}

.status-overdue {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-error-base;
	background-color: $color-error-light;
	padding: .8rem 1.6rem .8rem 1.6rem;
	border-radius: 15rem;
	border: none;
	font-size: 1.4rem;
	line-height: normal;
	line-spacing: 0;
	font-family: $font-regular;
	text-transform: none;
}

.fiscal-form-container {
	display: flex;
	width: 100%;
	justify-content: center;
}