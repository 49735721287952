//Typography

$font-regular: 'Inter-Regular', sans-serif;
$font-medium: 'Inter-Medium', sans-serif;
$font-semibold: 'Inter-SemiBold', sans-serif;
$font-bold: 'Inter-Bold', sans-serif;

//Colors

$text-main-color: #333333;
$text-btn-color: #6F7782;
$outline-color: #E8ECEE;
$outline-color-dark: #D3D7D9;
$sidebar: #151B26;
$sidebar-submenu: #0A0D12;
$sidebar-logo-background: #07090D;
$sidebar-border: #273240;
$sidebar-icon: #CBD4DB;
$dashboard-background: #E5E5E5;
$input-background: #F5F7FA;


$blue-theme-base: #0091D9;
$blue-theme-light: #EDF9FF;

$status-active-base: #00D97E;
$status-active-light: #CCF7E5;

$status-pending-base: #6F7782;
$status-pending-light: #E8ECEE80;

$color-error-base: #E63757;
$color-error-light: #FAD7DD;

$color-warning-base: #F6C343;
$color-warning-light: #FDF3D9;

//calendar variables

$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;