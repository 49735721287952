.recover-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(to bottom, $blue-theme-base 50%, white 50%);
	height: 100vh;
}

.recover-content-container {
	display: flex;
	flex-direction: column;
	background-color: white;
	width: 49rem;
	border: .1rem solid $outline-color;
	border-radius: .6rem;
	align-items: center;
}

.recover-form-header {
	display: flex;
	height: 11rem;
	width: 99%;
	align-items: center;
	border-bottom: .1rem solid $outline-color;
}

.recover-logo-img {
	margin-left: 6rem;
}

.recover-form-container {
	margin-top: 6rem;
	margin-bottom: 10rem;
	width: 36rem;
}

.recover-form-title {
	font-family: $font-bold;
	font-size: 2.4rem;
	align-self: flex-start;
	margin-bottom: 4rem;
}

.recover-form-label {
	font-family: $font-medium;
	font-size: 1rem;
}

.recover-form-input {
	font-family: $font-regular;
	font-size: 1.4rem;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	border: none;
	border-radius: .5rem;
	background-color: $input-background;
	padding: 1rem;
	padding-right: 4.5rem;
	height: 5rem;
	width: 36rem;

	&:disabled {
		opacity: 0.4;
	}
}

.reset-btn {
	font-family: $font-medium;
	font-size: 1.4rem;
	color: white;
	width: 36rem;
	height: 5rem;
	border: none;
	border-radius: .5rem;
	background-color: $blue-theme-base;
	margin-top: 3.5rem;
	text-transform: none;
	cursor: pointer;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.recover-success-text {
	font-size: 1.4rem;
	margin-bottom: 5rem;
}

.recover-back-home-btn {
	font-family: $font-medium;
	font-size: 1.4rem;
	color: white;
	width: 36rem;
	height: 5rem;
	border: none;
	border-radius: .5rem;
	background-color: $blue-theme-base;
	text-transform: none;
	cursor: pointer;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.container__loading {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}