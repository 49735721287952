.container__page {
	display: flex;
	flex-direction: column;
	//height: calc(100vh - 12rem);
	flex-grow: 1;
	padding-right: 3rem;
	padding-left: 3rem;
	padding-bottom: 5rem;
	padding-top: 5rem;
	background-color: $input-background;
}

.header__page {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 3.2rem;
}

.header__page-title {
	font-family: $font-bold;
	font-size: 3.4rem;
}

.container__page-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	align-items: center;
	background-color: white;
	border-radius: .6rem;
	border: .1rem solid $outline-color;
	padding: 5rem;
}

.accordion-material-ui {
	border: .1rem solid $outline-color;
	border-radius: .6rem;
	box-shadow: none;
	margin-bottom: 2rem;
}

.accordion {
	width: 100%;

	.MuiIconButton-root {
		padding: 1.25rem;
		background-color: $blue-theme-light;
		color: $text-btn-color;
	}

	.MuiIconButton-edgeEnd {
		margin-right: 0;
	}

	.MuiAccordionSummary-content.Mui-expanded {
		margin: 0;
	}

	.MuiAccordion-root.Mui-expanded:last-child {
		margin-bottom: 2rem;
	}

}

.accordion__summary {
	padding: 0 3rem;
	border-bottom: 0;

	&--expanded {
		margin: 0;
		border-bottom: .1rem solid $outline-color;
	}

	&--content {
		margin: 0;
		height: 9rem;
		border-bottom: 0;
	}
}

.accordion__details {
	padding: 0;
}

.accordion__details-content {
	display: flex;
	align-items: flex-start;
	width: 100%;
	padding: 5rem 3rem 5rem 3rem;
}

.accordion__container-column {
	margin-left: 15rem;

	&--medium {
		display: flex;
		margin-top: 4rem;
	}

	&--small {
		&:not(:last-child) {
			margin-right: 9.8rem;
		}
	}
}

.accordion__summary-content {
	display: flex;
	align-items: center;
	width: 100%;
}

.btn__accordion--edit {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: 3rem;
	height: 5rem;
	width: 24rem;
	border: .1rem solid $outline-color;
	border-radius: .5rem;
	background-color: white;
	color: $text-btn-color;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;

	&:hover {
		color: $blue-theme-base;
		background-color: white;
	}
}

.accordion__user-info {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}
}

.accordion__title {
	font-family: $font-semibold;
	font-size: 2.2rem;
}

.accordion__user-name {
	font-family: $font-semibold;
	font-size: 2.8rem;
}

.icon__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.2rem;
	height: 4.2rem;
	background-color: $blue-theme-light;
	border-radius: 50%;
	color: $blue-theme-base;
	margin-right: 1.2rem;
}

.container__form-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border: .2rem solid $blue-theme-base;
	border-radius: .6rem;
	padding: 3rem 3rem 10rem 3rem;
	margin-bottom: 2rem;
}

.container__form--user-profile {
	width: 60rem;
}

.edit-form {
	//padding-top: 3rem;
	padding-bottom: 5rem;
}

.btn__edit-form--cancel {
	align-self: flex-end;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	width: 22.8rem;
	border: .1rem solid $blue-theme-base;
	border-radius: .5rem;
	background-color: white;
	color: $blue-theme-base;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;

	&:hover {
		background-color: scale-color($blue-theme-light, $lightness: 10%);
	}
}

.btn__form--save {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	width: 18rem;
	background-color: $blue-theme-base;
	border: none;
	border-radius: .5rem;
	color: white;
	font-family: $font-medium;
	font-size: 1.4rem;
	text-transform: none;
	margin-top: 5rem;

	&:hover {
		background-color: scale-color($blue-theme-base, $lightness: -20%);
	}
}

.form__title--user-profile {
	font-family: $font-bold;
	font-size: 2.4rem;
}

.text__user-info {
	font-family: $font-regular;
	font-size: 1.6rem;
}

.text__title {
	font-family: $font-bold;
}

.expand-icon {
	height: 2rem;
	width: 2rem;
}

.btn-txt {
	margin-left: 1rem;
}