// Media query manager

/* 
$breakpoint choices:

- small-screen = 0 - 600px;
- medium-screen = 600 - 900px;
- general-screen = 900 - 1200px;
- large-screen = 2200px+;

*/

//1em = 16px;

@mixin respond($breakpoint) {
	@if $breakpoint == medium-screen {
		@media (max-width: 56.25em) { @content }; //900px
	}

	@if $breakpoint == general-screen {
		@media (max-width: 75em) { @content }; //1200px
	}

	@if $breakpoint == large-screen {
		@media (min-width: 137.5em) { @content }; //2200px
	}

}

//calendar mixins

%navigation-chevron {
  border-color: $datepicker__muted-color;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;

  &--disabled,
  &--disabled:hover {
    border-color: $datepicker__navigation-disabled-color;
    cursor: default;
  }
}

%triangle-arrow {
  margin-left: -$datepicker__triangle-size * 0.5;
  position: absolute;
  width: 0;

  &::before,
  &::after {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: $datepicker__triangle-size;
    left: -$datepicker__triangle-size;
  }

  &::before {
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::after {
    top: 0;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}